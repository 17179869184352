<template>
  <v-card-text>
    <v-data-table
      :headers="headers"
      :footer-props="footerProps"
      :items="languages"
      :loading="isLoading"
      :server-items-length="languagesCount"
      :options.sync="options"
    >
      <template v-slot:item.name="{ item }">
        <router-link
          class="view-link"
          :to="{ name: 'LanguagesView', params: { id: item.id } }"
        >
          {{ item.name }}
        </router-link>
      </template>
      <template v-slot:item.description="{ item }">
        {{ item.description }}
      </template>
      <template v-slot:item.isoCode="{ item }">
        {{ item.isoCode }}
      </template>
      <template v-slot:no-data>
        <no-data-alert />
      </template>
    </v-data-table>
  </v-card-text>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import NoDataAlert from "@admin/components/NoDataAlert.vue";
import _ from "lodash";

export default {
  name: "LanguagesIndex",
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: "Language",
          value: "name",
        },
        {
          text: "Display Name",
          value: "description",
        },
        {
          text: "ISO Code",
          value: "iso_code",
          sortable: false,
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
      },
    };
  },
  computed: {
    ...mapState("systemLanguages", ["languages", "languagesCount"]),
    ...mapState(["footerProps"]),
    ...mapGetters(["acceptRoles"]),
    showLanguagesAddButton() {
      return this.acceptRoles(["Admin", "Super User"]);
    },
    pageAndSortData() {
      const data = (({ page, itemsPerPage, sortBy }) => ({
        page,
        limit: itemsPerPage,
        sortBy,
      }))(this.options);

      if (this.options.sortBy.length) {
        data.sortBy = [];
        for (let i = 0; i < this.options.sortBy.length; i++) {
          const sortField = this.options.sortBy[i];
          const desc = this.options.sortDesc[i] === true ? "-" : "";
          data.sortBy.push(desc + sortField);
        }
      }

      return data;
    },
  },
  watch: {
    pageAndSortData: {
      async handler(currentValue, oldValue) {
        if (_.isEqual(currentValue, oldValue)) return;

        this.isLoading = true;
        await this.getLanguages(currentValue);
        this.isLoading = false;
      },
      deep: true,
    },
  },
  components: {
    NoDataAlert,
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    ...mapActions("systemLanguages", ["getLanguages", "clearLanguages"]),
  },
  async beforeMount() {
    if (!this.languages.length) {
      this.isLoading = true;
      await this.getLanguages();
      this.isLoading = false;
    }

    this.setBreadcrumbs();
    this.setTitleObject("Languages");
    if (this.showLanguagesAddButton) {
      this.setActionButtons([
        {
          attributes: {
            to: "/languages/add",
          },
          component: "AddButton",
          text: "Add Language",
          index: 0,
        },
      ]);
    }
  },
  destroyed() {
    this.clearLanguages();
    this.clearActionButtons();
    this.clearTitleObject();
  },
};
</script>

<style scoped></style>
